import React from 'react'
import LayoutDefault from "../layouts";
import * as classes from '../sass/style.module.scss'
import { L10nProvider } from '@logdoc/l10n';

const Si = () => {
	return (
		<L10nProvider>
			<Sink />
		</L10nProvider>
	)
}

export const Sink = () => {
	return (
		<LayoutDefault>
			<section className={classes.sec}>
				<div className={classes.secBreadcrumbs}>
					<span>Расширение</span>
					<span>/</span>
					<span>Sink плагины</span>
				</div>
				<h2 className={classes.secTitle}>
					Sink плагины
				</h2>
				<div className={classes.secDescription}>
					<p>
						Logdoc поддерживает три сетевых протокола передачи данных: <span
						className={classes.textOrange}>tcp/ip</span>, <span
						className={classes.textOrange}>udp</span> и <span className={classes.textOrange}>http</span>.
					</p>
					<p>
						Ульи в стандартном варианте поддерживают три формата (протокола) структурирования передачи
						данных: <span className={classes.textOrange}>Syslog</span>, <span
						className={classes.textOrange}>Journald</span> и собственный - <span
						className={classes.textOrange}>Logdoc</span>.
					</p>
					<p>
						Возможностей по поддержке иных сетевых протоколов Logdoc не предоставляет.
					</p>
					<p>
						Для поддержки иных форматов структурирования передачи данных достаточно реализовать
						соответствующий <a href="#">плагин для соты</a> посредством реализации интерфейса org.logdoc.sdk.SinkPlugin
					</p>
				</div>
			</section>
		</LayoutDefault>
	);
};

export default Si;